<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  name:"App",
  components:{
    Header,
    Footer
  },
  data() {
    return {
      
    }
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
  font-family: 'kanit';
}

.container-fluid{
  margin: 0;
  padding: 0;
}
</style>
