<template>
  <div class="home"></div> 
</template>

<script>
import Axios from 'axios';

export default {
  name: "home",
  components: {
    
  },
  created() {

    Axios.get('/api/user')
        .then(res => {
          const userLogin = res.data.role;
          if (userLogin === "statics") {
            this.$router.push({ name: "form" });
          }
          else if (userLogin === "card") {
            this.$router.push({ name: "cardpage" });
          }
          // else if (userLogin === "info") {
          //   this.$router.push({ name: "info" });
          // }        
        })
  }
};
</script>
