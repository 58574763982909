<template>
  <div class="home container">

    <div v-show="cardListLength == 0">
      <div ref="document" id="cardId">
        <div class="row">
          <div class="content">
            <!-- <img :src="'/img/front'+type+'.png'" class="card-img-top" alt="card"> -->
            <img :src="bg_default" class="card-img-top" alt="card">
            <div class="avatar" v-if="!image"><img :src="'/img/avatar/'+ personalid + '.jpg'" alt="card"></div>
            <div class="avatar" v-if="image"><img :src="image" alt="card"></div>
            <div v-if="firstname" class="firstname"><span>ชื่อ <strong> {{ firstname }} </strong></span></div>
            <div v-if="lastname" class="lastname"><span>นามสกุล <strong> {{ lastname }} </strong></span></div>
            <div class="top-right"> <strong>หมายเลข {{runnumber}}</strong> </div>
            <div class="centered-h"><h4> {{ department }} </h4></div>
            <div v-show="cardtype != 'C' " class="datelabel"><span class="text-danger"> {{ date }} </span></div>
            <div v-show="cardtype == 'C' " class="datelabelc"><span class="text-danger"> {{ date }} </span></div>
            <div v-if="cardtype != 'ALL ZONE' && cardtype != 'I' && cardtype != 'J' && cardtype != 'C' " class="type"><span class="text-danger"> {{ cardtype }} </span></div>
                <div v-if="cardtype == 'J' " class="typej"><span class="text-danger"> {{ cardtype }} </span></div>
                <div v-if="cardtype == 'I' " class="typei"><span class="text-danger"> {{ cardtype }} </span></div>
                <div v-if="cardtype == 'C' " class="typec"><span class="text-danger"> {{ cardtype }} </span></div>
                <div v-if="cardtype == 'ALL ZONE' " class="typeall"><span class="text-danger"> {{ cardtype }} </span></div>
          </div>

          <!-- <div class="content" v-if="cardtype == 'all zone' ">
            <img :src="bg_allzone" class="card-img-top" alt="card">
            <div class="avatar" v-if="!image"><img :src="'/img/avatar/'+ personalid + '.jpg'" alt="card"></div>
            <div class="avatar" v-if="image"><img :src="image" alt="card"></div>
            <div v-if="firstname" class="firstname"><span>ชื่อ <strong> {{ firstname }} </strong></span></div>
            <div v-if="lastname" class="lastname"><span>นามสกุล <strong> {{ lastname }} </strong></span></div>
            <div class="top-right"> <strong>หมายเลข {{runnumber}}</strong> </div>
            <div class="centered-h"><h4> {{ department }} </h4></div>
            <div class="datelabel"><span class="text-danger"> {{ date }} </span></div>
            <div class="typeall"><span class="text-danger"> {{ cardtype }} </span></div>
          </div> -->
        </div>
      </div>

      <div ref="documentBack" id="cardId-Back">
        <div class="row">
          <div class="content">
            <img :src="'/img/back.png'" class="card-img-top" alt="card">
          </div>
        </div>
      </div>
    </div>

    <div v-show="cardListLength > 0 " v-for="(item, index) in data" :key="index">
        <div :ref="'document_' + index" :id="'doc'+index">
          <div class="row">
            <div class="content" v-for="(data, i) in item" :key="i">
              <div>
                <!-- <img :src="'/img/front'+type+'.png'" class="card-img-top" alt="card"> -->
                <img :src="bg_default" class="card-img-top" alt="card">
                <div class="avatar" v-if="!data.image"><img :src="'/img/avatar/'+ data.personalid + '.jpg'" alt="card"></div>
                <div class="avatar" v-if="data.image"><img :src="data.image" alt="card"></div>
                <div v-if="data.firstname" class="firstname"><span>ชื่อ <strong> {{ data.firstname }} </strong></span></div>
                <div v-if="data.lastname" class="lastname"><span>นามสกุล <strong> {{ data.lastname }} </strong></span></div>
                <div class="top-right"> <strong>หมายเลข {{data.runnumber}}</strong> </div>
                <div class="centered-h"><h4> {{ data.department }} </h4></div>
                <div v-show="data.cardtype != 'C' " class="datelabel"><span class="text-danger"> {{ data.date }} </span></div>
                <div v-show="data.cardtype == 'C' " class="datelabelc"><span class="text-danger"> {{ data.date }} </span></div>
                <div v-if="data.cardtype != 'ALL ZONE' && data.cardtype != 'I' && data.cardtype != 'J' && data.cardtype != 'C' " class="type"><span class="text-danger"> {{ data.cardtype }} </span></div>
                <div v-if="data.cardtype == 'J' " class="typej"><span class="text-danger"> {{ data.cardtype }} </span></div>
                <div v-if="data.cardtype == 'I' " class="typei"><span class="text-danger"> {{ data.cardtype }} </span></div>
                <div v-if="data.cardtype == 'C' " class="typec"><span class="text-danger"> {{ data.cardtype }} </span></div>
                <div v-if="data.cardtype == 'ALL ZONE' " class="typeall"><span class="text-danger"> {{ data.cardtype }} </span></div>
              </div>

            </div>
          </div>
        </div>

        <div :ref="`documentBack${index}`" :id="'back'+index">
          <div class="row">
            <div class="content" v-for="i in 6" :key="i">
              <img :src="'/img/back.png'" class="card-img-top" alt="card">
            </div>
          </div>
        </div>
    </div>

    <div class="modal fade bd-example-modal-lg" id="pandingModal" data-backdrop="static" data-keyboard="false" tabindex="-1">
    <div class="modal-dialog modal-sm">
            <div class="modal-content" style="width: 48px">
                <span class="fas fa-spinner fa-spin fa-3x"></span>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
import VueHtml2pdf from 'vue-html2pdf';
import jsPDF from 'jspdf';
import axios from 'axios';

export default {
  name: 'Home',
  components: {
    VueHtml2pdf
  },
  data() {
    return {
      data: [],
      genType: '',
      department: '',
      image: '',
      firstname: '',
      lastname: '',
      date: '',
      cardtype: '',
      runnumber: '',
      type: '',
      studentnumber: '',
      personalid: '',
      cardListLength: 0,
      front: '',
      back: '',
      bg_default: '',
      bg_allzone: '',
      permission: ''
    }
  },
  mounted() {
      const length = this.$route.params.length;
      if (length == 1) {
        this.genType = 1
        this.type = this.$route.params.info.type;
        this.department = this.$route.params.info.department;
        this.firstname = this.$route.params.info.firstname;
        this.lastname = this.$route.params.info.lastname;
        this.date = this.$route.params.info.date;
        this.cardtype = this.$route.params.info.cardtype;
        this.runnumber = this.$route.params.info.runnumber;
        this.personalid = this.$route.params.info.personalid;
        this.image = this.$route.params.info.image;
        this.studentnumber = this.$route.params.info.studentnumber;
      }else{
        this.data = this.$route.params.info;
        this.genType = 2;
        this.cardListLength = this.data.length;
        this.type = this.data[0][0].type;
      }

      axios.get('/api/user').then(res => { 
        this.permission = res.data.permission;

        axios.get('/api/background/default/' + this.type + '/' + this.permission).then(res => {
        this.bg_default = res.data.background;

        this.exportToPDF();
        $('#pandingModal').modal('show');

        // axios.get('/api/background/all zone/' + this.type).then(res => {
        //   this.bg_allzone = res.data.background;
          
        // })

      })
      });

  },
  methods: {
    generateReport () {
      // this.$refs.html2Pdf.generatePdf()
      var doc = new jsPDF('p', 'pt', 'A4');
      
      const docs = document.getElementsByTagName('div')[0];

        if (docs) {
            doc.html(document.getElementById('cardId'), {
            x: 10,
            callback: function (pdf) {
              pdf.save('test.pdf');
            }
          });
        }
    },
    exportToPDF() {
      this.someModal = new bootstrap.Modal(document.getElementById('pandingModal'));

      if (this.cardListLength == 0) {
        const opt = {
          margin: 1,
          pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
          filename: "บัตรติดหน้าอก (ด้านหน้า).pdf",
          image: { type: "png", quality: 0.98 },
          html2canvas: { scale: 4, letterRendering: true },
          jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
        }

        const optBack = {
          margin: 1,
          pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
          filename: "บัตรติดหน้าอก (ด้านหลัง).pdf",
          
          html2canvas: { scale: 4, letterRendering: true },
          jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
        }

        //ด้านหลัง
        html2pdf().from(this.$refs.documentBack).set(optBack).save();

        html2pdf().from(this.$refs.document).set(opt).outputPdf().then(response => {
          this.someModal.hide();
          
        }).save();
      } else {

        for (let i = 0; i < this.data.length; i++) {
          const opt = {
            margin: 1,
            pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
            filename: `บัตรติดหน้าอก (ด้านหน้า) ${i + 1}.pdf`,
            
            html2canvas: { scale: 4, letterRendering: true },
            jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
          }

          const bopt = {
            margin: 1,
            pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
            filename: `บัตรติดหน้าอก (ด้านหลัง) ${i + 1}.pdf`,
            
            html2canvas: { scale: 4, letterRendering: true },
            jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
          }

          this.$nextTick(() => {
            if (i == this.data.length - 1) {
              html2pdf().from(document.getElementById(`doc${i}`)).set(opt).outputPdf().then(response => {
                this.someModal.hide();
                
              }).save();

              html2pdf().from(document.getElementById(`back${i}`)).set(bopt).save();
            }else{
              html2pdf().from(document.getElementById(`doc${i}`)).set(opt).save();

              html2pdf().from(document.getElementById(`back${i}`)).set(bopt).save();
            }
          })

        }

      }
    },
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=PT+Serif:wght@700&family=Sarabun:wght@100;200;300;400;500&display=swap');
*{
  font-family: 'Sarabun', sans-serif !important;
}

.card-img-top{
  border: 1px solid #333;
}

.bd-example-modal-lg .modal-dialog{
    display: table;
    position: relative;
    margin: 0 auto;
    top: calc(50% - 24px);
  }
  
  .bd-example-modal-lg .modal-dialog .modal-content{
    background-color: transparent;
    border: none;
  }

  .content {
    position: relative;
    text-align: center;
    width: 430px;
    height: 260px;
  }

  .content img{
    /* border: 1px solid #333; */
  }

  /* Centered text */
  .centered-h {
    position: absolute;
    top: 69%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  .avatar {
    position: absolute;
    top: 22%;
    left: 15%;
    transform: translate(-50%, -50%);
  }

  .avatar img {
    width: 90px;
    height: 100px;
  }

  .firstname {
    position: absolute;
    top: 42%;
    text-align: left;
    left: 4.5%;
    width: 100%;
  }

  .firstname span {
    font-size: 14px;
  }

  .lastname {
    position: absolute;
    top: 50%;
    text-align: left;
    left: 4.5%;
    width: 100%;
  }

  .lastname span {
    font-size: 14px;
  }

  .datelabel {
    position: absolute;
    bottom: 1%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  .datelabel span {
    font-size: 14px;
    border: 2px solid #dc3545;
    padding: 3px;
  }

  .datelabelc {
    position: absolute;
    bottom: 1%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  .datelabelc span {
    font-size: 14px;
  }

  .centered {
    position: absolute;
    top: 45%;
    left: 50%;
    width: 100%;
  }

  .type {
    position: absolute;
    bottom: 5%;
    right: 5%;
    border: 3px solid #dc3545;
    min-width: 70px;
  }

  .type span{
    font-size: 40px;
    font-family: 'PT Serif', serif !important;
    padding: 1px;
  }

  .typec {
    position: absolute;
    bottom: -1%;
    right: 6%;
  }

  .typec span{
    font-size: 60px;
    font-family: 'PT Serif', serif !important;
  }

  .typei {
    position: absolute;
    bottom: 5%;
    right: 5%;
    border: 3px solid #dc3545;
    width: 70px;
  }

  .typei span{
    font-size: 50px;
    font-family: 'PT Serif', serif !important;
  }

  .typej {
    position: absolute;
    bottom: 5%;
    right: 5%;
    border: 3px solid #dc3545;
    width: 70px;
  }

  .typej span{
    font-size: 50px;
    font-family: 'PT Serif', serif !important;
  }

  .typeall {
    position: absolute;
    bottom: 7.5%;
    right: 5%;
  }

  .typeall span{
    font-size: 24px;
    font-family: 'PT Serif', serif !important;
    border: 3px solid #dc3545;
    padding: 5px;
  }

  .typeO {
    position: absolute;
    bottom: 2%;
    right: 5%;
  }

  .typeO span{
    font-size: 50px;
    font-family: 'PT Serif', serif !important;
  }

  /* .centered span{
    font-weight: 500 !important;
  } */

  .top-right {
    position: absolute;
    top: 3%;
    right: 4%;
    text-align: right;
  }

  .no-padding{
    padding: 0 !important;
    margin: 0 !important;
  }
</style>
