<template>
  <footer class="bg-light text-black text-center text-md-start">
  <div class="text-center p-3">
    <span>Copyright © 2022 Chiang Mai University, All rights reserved. by Central Administration Division : Office of the President</span>
  </div>
</footer>
</template>

<script>

export default {
  name: 'Form',
  components: {
    
  },
  data() {
    return {
      
    }
  },
}
</script>

<style scoped>
    footer{
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
        margin-bottom: 0px;
    }
    span{
      font-size: 14px;
    }
</style>
