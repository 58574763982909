import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import VueDataTables from 'vue-data-tables'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import JsonExcel from "vue-json-excel"

locale.use(lang)

Vue.config.productionTip = false
Vue.use(VueSidebarMenu);
Vue.use(VueDataTables);
Vue.use(ElementUI);
Vue.component("downloadExcel", JsonExcel);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
