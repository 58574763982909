import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import HomeCard from '../views/Card/Home.vue';
import Card from '../views/Card/Card.vue';
import Login from '../views/Login.vue';
import Axios from 'axios';
import View from '../views/genPDF.vue';

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'home',
        component: Home,
        meta: { auth: true }
    },
    {
        path: '/view',
        name: 'view',
        component: View
    },
    {
        path: '/card/:type',
        name: 'card',
        component: Card,
        meta: { auth: ['card'] }
    },
    {
        path: '/cardpage',
        name: 'cardpage',
        component: HomeCard,
        meta: { auth: ['card'] }
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const auth = to.meta.auth;
    if (!auth) return next();
    Axios.get('/api/user')
        .then(res => {
            if (res.data.status == false) {
                next({ name: 'login' });
            } else {
                if (!Array.isArray(auth)) return next();
                const userLogin = res.data.role;
                if (auth.indexOf(userLogin) >= 0) return next();
            }
        })
        .catch(err => {
            next({ name: 'login' });
        })
})

export default router