<template>
  <div class="container-fluid">
    <div class="login-dark">
      <div>
      </div>
         <form>
            <div class="logo">
              <img class="logo" src="@/assets/img/logo.png" alt="logo">
            </div>
            <div class="form-group">
              <input class="form-control" type="text" name="email" v-on:keyup.enter="onLogin()" placeholder="Username" v-model="u_username">
            </div>
            <div class="form-group">
              <input class="form-control" type="password" name="password" v-on:keyup.enter="onLogin()" placeholder="Password" v-model="u_password">
            </div>
            <div class="form-group">
              <button type="button" class="btn btn-outline-success btn-block mt-4" @click="onLogin()">Log In</button>
              <hr>
            </div>
          </form>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2'

export default {
  name: 'Home',
  components: {
    
  },
  data() {
    return {
      u_username: '',
      u_password: ''
    }
  },
  mounted() {
    axios.get('/api/user')
        .then(res => {
            if (res.data != 'denied') {
              this.$router.push({ name: 'home' });
            }
        })
  },
  methods: {
    onLogin(){
      axios.post('/api/user', {
        username: this.u_username,
        password: this.u_password
      }).then(res => {
        if (res.data == 'pass') {
          this.$router.push({ name: 'home' });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Username or Password Invalid!'
          })
        }
      });
    }
  },
}
</script>

<style scoped>
 .required:after {
    content:" *";
    color: red;
  }

.btn-block {
	display: block;
	width: 100%;
}

.btn-block + .btn-block {
	margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
	width: 100%;
}
.container-fluid{
  padding: 0;
}

.logo{
  width: 100%;
  height: auto;
  margin-bottom: 7%;
}

.login-dark {
  height:100vh;
  background: url('../assets/img/bg.png');
  background-size:cover;
  position:relative;
}

.login-dark .title {
  transform:translate(-50%, -50%);
  position:absolute;
  top:25%;
  left:50%;
}

.login-dark .title2 {
  transform:translate(-50%, -50%);
  position:absolute;
  bottom: 5%;
  left:50%;
}

p{
  color: #fff;
}

.login-dark form {
  max-width:320px;
  width:90%;
  background-color:rgba(255, 255, 255, 0.7);
  padding:40px;
  border-radius:4px;
  transform:translate(-50%, -50%);
  position:absolute;
  top:55%;
  left:50%;
  color:#333;
  box-shadow:3px 3px 4px rgba(0,0,0,0.2);
}

.login-dark .illustration {
  text-align:center;
  padding:15px 0 20px;
  font-size:100px;
  color:#2980ef;
}

.login-dark form .form-control {
  background:none;
  border:none;
  border-bottom:1px solid #434a52;
  border-radius:0;
  box-shadow:none;
  outline:none;
  color:inherit;
}

.login-dark form .btn-primary {
  background:#214a80;
  border:none;
  border-radius:4px;
  padding:11px;
  box-shadow:none;
  margin-top:26px;
  text-shadow:none;
  outline:none;
}

.login-dark form .btn-primary:hover, .login-dark form .btn-primary:active {
  background:#214a80;
  outline:none;
}

.login-dark form .forgot {
  display:block;
  text-align:center;
  font-size:12px;
  color:#6f7a85;
  opacity:0.9;
  text-decoration:none;
}

.login-dark form .forgot:hover, .login-dark form .forgot:active {
  opacity:1;
  text-decoration:none;
}

.login-dark form .btn-primary:active {
  transform:translateY(1px);
}
</style>
